import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddRedirect from "./AddRedirect";
import { auth, db } from "./firebaseconfig";
import Login from "./Login";
import RedirectPage from "./RedirectPage";

function Redirect() {
  const [status, setStatus] = useState("redirect");
  let { user, id } = useParams();
  const docRef = doc(db, "Users", `${user.toLowerCase()}`);

  useEffect(() => {
    const getFbDocument = async () => {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && id.toLowerCase() in docSnap.data().redirects) {
        window.location.replace(docSnap.data().redirects[id.toLowerCase()]);
      } else if (auth.currentUser) {
        setStatus("add");
      } else {
        setStatus("login");
      }
    };

    getFbDocument();
  }, []);

  return status === "redirect" ? (
    <RedirectPage />
  ) : status === "add" ? (
    <AddRedirect id={id} />
  ) : (
    <Login />
  );
}

export default Redirect;
