import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBd6XwCTkgjIEoHrEQJDSNw72exk5KuCyI",
  authDomain: "aerts-a0753.firebaseapp.com",
  projectId: "aerts-a0753",
  storageBucket: "aerts-a0753.appspot.com",
  messagingSenderId: "700569576948",
  appId: "1:700569576948:web:641abfd83fd56fd24145a1",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
