import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "./firebaseconfig";

function Home() {
  const [users, setUsers] = useState();

  const getUsers = async () => {
    const usersSnapshot = await getDocs(collection(db, "Users"));
    let usersArr = [];
    usersSnapshot.forEach((user) => {
      usersArr.push(user.data());
    });
    setUsers(usersArr);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div id="home-container">
        {users &&
          users.map((userObj) => {
            return (
              <Link
                to={`/${userObj.short}`}
                className="home-item"
                style={{
                  backgroundColor: userObj.color,
                  textDecoration: "none",
                }}
                key={userObj.short}
              >
                <p className="home-name">{userObj.name}</p>
              </Link>
            );
          })}
      </div>
    </>
  );
}

export default Home;
