export default function RedirectPage({}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "#f0f0f0",
      }}
    >
      <h1 style={{ color: "#333", fontFamily: "Arial, sans-serif" }}>
        Redirecting...
      </h1>
      <p style={{ color: "#666", fontFamily: "Arial, sans-serif" }}>
        Please wait while we redirect you to the desired page.
      </p>
      <footer
        style={{
          position: "absolute",
          bottom: "20px",
          color: "#999",
        }}
      >
        Powered by{" "}
        <a
          style={{
            color: "#999",
            textDecoration: "none",
          }}
          href="https://robinaerts.be"
          target="_blank"
          rel="noreferrer"
        >
          <strong>robinaerts.be</strong>
        </a>
      </footer>
    </div>
  );
}
