import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddRedirect from "./AddRedirect";
import { auth, db } from "./firebaseconfig";
import { Link } from "react-router-dom";

function User() {
  let { user } = useParams();
  const [userData, setUserData] = useState();
  const [addRedirect, setAddRedirect] = useState(false);

  const currentUser = auth.currentUser;

  const getUser = async () => {
    const docRef = doc(db, "Users", `${user}`);
    const userSnapshot = await getDoc(docRef);
    setUserData(userSnapshot.data());
  };

  const removeItem = async (e) => {
    const short = e.target.parentNode.parentElement.id;

    let redirectObject = { ...userData.redirects };
    delete redirectObject[short];

    console.log(redirectObject);

    const userRef = doc(db, "Users", userData.short);
    await updateDoc(userRef, {
      redirects: redirectObject,
    });
    window.location.reload();
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {userData && (
        <div id="user-container">
          <h2>
            <Link
              style={{
                color: "black",
                textDecoration: "none",
              }}
              to={"/"}
            >
              &lt;
            </Link>{" "}
            {userData.name}
          </h2>
          {Object.entries(userData.redirects).map(([key, value]) => {
            return (
              <div id={key} key={key}>
                <p className="url-container">
                  <a
                    target="_blank"
                    className="short-url"
                    href={value}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    aerts.ga/{userData.short}/{key}
                  </a>
                  {userData.email === currentUser.email && (
                    <span className="bin" onClick={(e) => removeItem(e)}>
                      🗑️
                    </span>
                  )}
                </p>
              </div>
            );
          })}
          {userData.email === currentUser.email && (
            <button
              onClick={() => setAddRedirect(true)}
              className="add-redirect-btn"
            >
              ADD
            </button>
          )}
        </div>
      )}
      {addRedirect && <AddRedirect />}
    </>
  );
}

export default User;
