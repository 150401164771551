import {
  doc,
  updateDoc,
  collection,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth, db } from "./firebaseconfig";
import { Link } from "react-router-dom";

function AddRedirect(props) {
  let { user } = useParams();
  const [userData, setUserData] = useState({ short: "" });
  const [newShort, setNewShort] = useState(props.id || "");

  const userEmail = auth.currentUser.email;

  const getUser = async () => {
    const usersRef = collection(db, "Users");
    const q = query(usersRef, where("email", "==", userEmail));
    const userSnapshot = await getDocs(q);
    setUserData(userSnapshot.docs[0].data());
  };

  useEffect(() => {
    getUser();
  }, []);

  const addRedirect = async (e) => {
    e.preventDefault();
    const short = e.target[0].value.toLowerCase();
    const url = e.target[1].value;
    const redirects = {};
    redirects[short] = url;

    const userRef = doc(db, "Users", userData.short);

    await updateDoc(userRef, {
      redirects: { ...userData.redirects, ...redirects },
    });
    window.location.reload();
  };

  return (
    <div id="add-outer-container">
      <div id="add-inner-container">
        <h1 style={{ fontSize: "1.8rem", color: "#333" }}>
          New Redirect{" "}
          <Link
            style={{
              fontSize: "1.3rem",
              color: "gray",
              textDecoration: "none",
            }}
            to="/"
          >
            ⓧ
          </Link>
        </h1>
        <form id="add-redirect-form" onSubmit={(e) => addRedirect(e)}>
          {props.id ? (
            <input
              placeholder="Identifier"
              className="input-box"
              type="text"
              defaultValue={props.id.toLowerCase()}
              onChange={(e) => setNewShort(e.target.value)}
            ></input>
          ) : (
            <input
              placeholder="Identifier"
              className="input-box"
              type="text"
              onChange={(e) => setNewShort(e.target.value)}
            ></input>
          )}
          <input
            style={{ marginBottom: "0" }}
            placeholder="URL (starts with https://)"
            className="input-box"
            type="text"
          ></input>
          <p>
            New URL:{" "}
            <b>
              aerts.ga/{userData.short}/{newShort.toLowerCase()}
            </b>
          </p>
          <button
            style={{ marginTop: "1rem" }}
            type="submit"
            className="add-redirect-btn"
          >
            Add
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddRedirect;
